@font-face {
    font-family: 'Figtree';
    src: url('../../assets/fonts/figtree/static/Figtree-Regular.ttf') format('truetype'),
         url('../../assets/fonts/figtree/woff/Figtree-Regular.woff') format('woff');
    font-weight: 400; /* Normal font weight */
}

@font-face {
    font-family: 'Figtree';
    src: url('../../assets/fonts/figtree/static/Figtree-SemiBold.ttf') format('truetype'),
         url('../../assets/fonts/figtree/woff/Figtree-SemiBold.woff') format('woff');
    font-weight: 600; /* Semi-bold font weight */
}

@font-face {
    font-family: 'Figtree';
    src: url('../../assets/fonts/figtree/static/Figtree-Bold.ttf') format('truetype'),
         url('../../assets/fonts/figtree/woff/Figtree-Bold.woff') format('woff');
    font-weight: 700; /* Bold font weight */
}

.title {
    font-size: 24px;
    margin-bottom: 8px;
    font-family: 'Figtree';
}