.button {
    padding: 6px 20px;
    border-radius: 100px;
    background-color: $secondary-color;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Figtree';
    cursor: pointer;
    border: 2px solid $secondary-color;
    text-decoration: none;
    color: #fff;
    word-break: keep-all;
    display: inline-block;
    line-height: 1.7em;
    width: fit-content;

    &:hover {
        background-color: $primary-color;
        color: #fff;
        transition-duration: 0.7s;
    }
}

.disable {
  opacity: 0.6;
  pointer-events: none;
}

.cta-button {
    padding:6px 20px;
    line-height: 1.7em;
    border-radius: 100px;
    background-color: $primary-color;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    border: 2px solid $primary-color;
    text-decoration: none;
    color: #fff;
    word-break: keep-all;
    display: inline-block;
    line-height: 1.7em;

    &:hover {
        background-color: $primary-color;
        color: #fff;
        transition-duration: 0.7s;
        border-color: $primary-color;
    }
  }

  .search-button {
    background-color: white;

    .active {
        background-color: black;
    }
  }

 /* Add this CSS to your project */
.swiper-button-prev,
.swiper-button-next {
  font-size: 18px !important;
  font-weight:bolder;
}

