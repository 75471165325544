p {
    margin-bottom: 8px;
    font-family: 'Figtree';
}

a {
    color: $secondary-color;
    font-family: 'Figtree';
}

.m-0-auto {
    margin: 0 auto;
}